<template>
    <div class="w-full h-auto absolute">
        <!-- navbar desktop -->
        <div class="hidden lg:block">
            <header class="p-header">
                <div class="menu-area">
                    <div class="vertical-align-containers">
                        <div class="navbar_position-left">
                            <div class="position-left-inner">
                                <a href="#container-slider" class="sm-clickScroll"><img class="logo max-h-16 xl:max-h-[75px]" src="../../public/images/logo_albasha_png.png" /></a>
                            </div>
                        </div>
                        <div class="navbar_position-center">
                            <nav class="main-menu">
                                <ul class="clearfix font_arponasans_light">
                                    <li>
                                        <a href="#container-slider" class="sm-clickScroll"><span class="item_outer"><span class="item_inner px-3 xl:px-5">INICIO</span></span></a>
                                    </li>
                                    <li>
                                        <a href="https://al-basha.cluvi.co/al-basha/menu-digital/home" target="_blank"><span class="item_outer"><span class="item_inner px-3 xl:px-5">MENU</span></span></a>
                                    </li>
                                    <li>
                                        <a href="#nosotros_section" class="sm-clickScroll"><span class="item_outer"><span class="item_inner px-3 xl:px-5">SOBRE NOSOTROS</span></span></a>
                                    </li>
                                    <li>
                                        <a href="#contacto_section" class="sm-clickScroll"><span class="item_outer"><span class="item_inner px-3 xl:px-5">CONTACTENOS</span></span></a>
                                    </li>
                                    <li>
                                        <a href="#reservation_section" class="sm-clickScroll"><span class="item_outer"><span class="item_inner px-3 xl:px-5">RESERVE SU MESA</span></span></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="navbar_position-right">
                            <div class="position-right-inner">
                                <div class="right-from-rrss">
                                    <a href="https://www.instagram.com/albashapanama" target="_blank"><font-awesome-icon class="text-2xl cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-instagram" /></a>
                                </div>
                                <div class="right-from-rrss">
                                    <a href="https://www.tiktok.com/@albashapanama" target="_blank"><font-awesome-icon class="text-2xl cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-tiktok" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
    
            <header id="sticky_on" class="sticky-header shadow-md inline-block">
                <div class="sticky-holder bg-gray-950">
                    <div class="sticky-vertical-align-containers">
                        <div class="sticky-position-left">
                            <div class="s-position-left-inner">
                                <a href="#container-slider" class="sm-clickScroll"><img class="logo max-h-16 xl:max-h-[70px]" src="../../public/images/logo_albasha_png.png" /></a>
                            </div>
                        </div>
                        <div class="sticky-position-center">
                            <nav class="main-menu">
                                <ul class="clearfix font_arponasans_light">
                                    <li><a href="#container-slider" class="sm-clickScroll"><span class="item_outer"><span class="item_inner px-3 xl:px-5">INICIO</span></span></a></li>
                                    <li><a href="https://al-basha.cluvi.co/al-basha/menu-digital/home" target="_blank"><span class="item_outer"><span class="item_inner px-3 xl:px-5">MENU</span></span></a></li>
                                    <li><a href="#nosotros_section" class="sm-clickScroll"><span class="item_outer"><span class="item_inner px-3 xl:px-5">SOBRE NOSOTROS</span></span></a></li>
                                    <li><a href="#contacto_section" class="sm-clickScroll"><span class="item_outer"><span class="item_inner px-3 xl:px-5">CONTACTENOS</span></span></a></li>
                                    <li><a href="#reservation_section" class="sm-clickScroll"><span class="item_outer"><span class="item_inner px-3 xl:px-5">RESERVE SU MESA</span></span></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="sticky-position-right">
                            <div class="s-position-right-inner">
                                <div class="s-right-from-rrss">
                                    <a href="https://www.instagram.com/albashapanama" target="_blank"><font-awesome-icon class="text-2xl cursor-pointer hover:text-[#FFFFFF] duration-300 ease-in-out" icon="fa-brands fa-instagram" /></a>
                                </div>
                                <div class="s-right-from-rrss">
                                    <a href="https://www.tiktok.com/@albashapanama" target="_blank"><font-awesome-icon class="text-2xl cursor-pointer hover:text-[#FFFFFF] duration-300 ease-in-out" icon="fa-brands fa-tiktok" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
        <!-- fin navbar desktop -->

        <!-- navbar mobile -->
        <div class="block lg:hidden">
            <header class="w-full px-5 py-3 bg-gray-950/75 z-50 relative">
                <div class="h-16 flex items-center">
                    <div class="flex-1">
                        <div class="max-w-20">
                            <a href="#container-slider" class="sm-clickScroll"><img class="h-auto max-h-16 max-w-20" src="../../public/images/logo_albasha_png.png" /></a>
                        </div>
                    </div>
                    <div class="float-none w-7">
                        <span class="text-2xl text-slate-300 cursor-pointer">
                            <font-awesome-icon icon="fa-solid fa-bars" v-if="!openNavbar" @click="openNavbarM('open')" />
                            <font-awesome-icon icon="fa-solid fa-xmark" v-else @click="openNavbarM('close')" />
                        </span>
                    </div>
                </div>
                <div class="w-full min-h-52 py-4" :class="openNavbar ? '!block' : '!hidden'">
                    <ul class="w-full h-auto border-b border-slate-200/25 pb-2 uppercase">
                        <li class="text-left py-3">
                            <a href="#container-slider" class="sm-clickScroll text-slate-200 cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out">Inicio</a>
                        </li>
                        <li class="text-left py-3">
                            <a href="https://al-basha.cluvi.co/al-basha/menu-digital/home" class="text-slate-200 cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out" target="_blank">Menu</a>
                        </li>
                        <li class="text-left py-3">
                            <a href="#nosotros_section" class="sm-clickScroll text-slate-200 cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out">Sobre Nosotros</a>
                        </li>
                        <li class="text-left py-3">
                            <a href="#contacto_section" class="sm-clickScroll text-slate-200 cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out">Contactenos</a>
                        </li>
                        <li class="text-left py-3">
                            <a href="#reservation_section" class="sm-clickScroll text-slate-200 cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out">Reserve su mesa</a>
                        </li>
                    </ul>
                    <div class="flex items-center space-x-4 pt-4 text-center">
                        <span class="text-[#EEC667]">
                            Siganos en:
                        </span>
                        <a href="https://www.instagram.com/albashapanama" target="_blank"><font-awesome-icon class="text-2xl cursor-pointer hover:text-slate-400 text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-instagram" /></a>
                        <a href="https://www.tiktok.com/@albashapanama" target="_blank"><font-awesome-icon class="text-2xl cursor-pointer hover:text-slate-400 text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-tiktok" /></a>
                        
                    </div>
                </div>
            </header>
            <header id="sticky_on_mob" class="translate-y-[-100%] w-full px-5 py-3 bg-gray-950 z-50 fixed transition transform duration-300 ease-in top-0">
                <div class="h-16 flex items-center">
                    <div class="flex-1">
                        <div class="max-w-20">
                            <a href="#container-slider" class="sm-clickScroll"><img class="h-auto max-h-16 max-w-20" src="../../public/images/logo_albasha_png.png" /></a>
                        </div>
                    </div>
                    <div class="float-none w-7">
                        <span class="text-2xl text-[#EEC667] cursor-pointer">
                            <font-awesome-icon icon="fa-solid fa-bars" v-if="!openNavbarMs" @click="openNavbarMS('open')" />
                            <font-awesome-icon icon="fa-solid fa-xmark" v-else @click="openNavbarMS('close')" />
                        </span>
                    </div>
                </div>
                <div class="w-full min-h-52 py-4" :class="openNavbarMs ? '!block' : '!hidden'">
                    <ul class="w-full h-auto border-b border-slate-200/25 pb-2 uppercase">
                        <li class="text-left py-3">
                            <a href="#container-slider" class="sm-clickScroll hover:text-slate-200 cursor-pointer text-[#EEC667] duration-300 ease-in-out">Inicio</a>
                        </li>
                        <li class="text-left py-3">
                            <a href="https://al-basha.cluvi.co/al-basha/menu-digital/home" target="_blank" class="hover:text-slate-200 cursor-pointer text-[#EEC667] duration-300 ease-in-out">Menu</a>
                        </li>
                        <li class="text-left py-3">
                            <a href="#nosotros_section" class="sm-clickScroll hover:text-slate-200 cursor-pointer text-[#EEC667] duration-300 ease-in-out">Sobre Nosotros</a>
                        </li>
                        <li class="text-left py-3">
                            <a href="#contacto_section" class="sm-clickScroll hover:text-slate-200 cursor-pointer text-[#EEC667] duration-300 ease-in-out">Contactenos</a>
                        </li>
                        <li class="text-left py-3">
                            <a href="#reservation_section" class="sm-clickScroll hover:text-slate-200 cursor-pointer text-[#EEC667] duration-300 ease-in-out">Reserve su mesa</a>
                        </li>
                    </ul>
                    <div class="flex items-center space-x-4 pt-4 text-center">
                        <span class="text-[#EEC667]">
                            Siganos en:
                        </span>
                        <a href="https://www.instagram.com/albashapanama" target="_blank"><font-awesome-icon class="text-2xl cursor-pointer hover:text-slate-400 text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-instagram" /></a>
                        <a href="https://www.tiktok.com/@albashapanama" target="_blank"><font-awesome-icon class="text-2xl cursor-pointer hover:text-slate-400 text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-tiktok" /></a>
                    </div>
                </div>
            </header>
        </div>

        <!-- fin navbar mobile -->
    </div>
</template>
<style>
.p-header {
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    width: 100%;
    z-index: 110;
    background-color: transparent;
}

.p-header .menu-area {
    height: 103px;
    position: relative;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    transition: background-color .3s ease;
    background-color: rgba(255, 255, 255, 0);
    color: #fff;
}

.p-header .menu-area .vertical-align-containers {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 33px;
    box-sizing: border-box;
}

.p-header .menu-area .vertical-align-containers .navbar_position-left {
    position: relative;
    float: left;
    z-index: 2;
    height: 100%;
}

.p-header .menu-area .vertical-align-containers .navbar_position-left .position-left-inner {
    vertical-align: middle;
    display: inline-block;
}

.p-header .menu-area .vertical-align-containers .navbar_position-left .position-left-inner .logo {
    height: auto;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    z-index: 1;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu > ul {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 100%;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu .clearfix::after, 
.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu .clearfix::before {
    content: " ";
    display: table;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu ul li {
    position: relative;
    display: inline-block;
    float: left;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-position: right;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu ul li a {
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    letter-spacing: 1px;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu ul li a span.item_inner:hover {
    color: #EEC667;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu > ul > li {
    height: 100%;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu > ul > li > a {
    height: 100%;
    position: relative;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    border-style: solid;
    border-width: 0;
    border-color: transparent;
    background-color: transparent;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu > ul > li > a > span.item_outer {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu > ul > li > a span.item_inner {
    letter-spacing: 3px;
    cursor: pointer;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu > ul > li > a span.item_inner::after {
    display: block;
    content: '';
    border-bottom: solid 1px #EEC667;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
}

.p-header .menu-area .vertical-align-containers .navbar_position-center .main-menu > ul > li > a span.item_inner:hover::after {
    transform: scaleX(1)
}


.p-header .menu-area .vertical-align-containers .navbar_position-right {
    position: relative;
    float: right;
    z-index: 2;
    height: 100%;
    text-align: right;
}

.p-header .menu-area .vertical-align-containers .navbar_position-right .position-right-inner {
    height: 100%;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
}

.p-header .menu-area .vertical-align-containers .navbar_position-right .position-right-inner .right-from-rrss {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding-left: 28px;
    position: relative;
}

.vertical-align-containers .navbar_position-left::before, .vertical-align-containers .navbar_position-center::before, .vertical-align-containers .navbar_position-right::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-right: -3px;
}

.sticky-header {
    margin: 0;
    top: -2px;
    left: 0;
    position: fixed;
    vertical-align: middle;
    width: 100%;
    height: 90px;
    z-index: 110;
    background-color: transparent;
    -webkit-transform: translateY(-279%);
    transform: translateY(-279%);
    -webkit-transition: -webkit-transform .33s ease;
    transition: transform .3s ease;
}

.sticky_navbar {
    transform: translateY(0)!important;
}

.sticky-header .sticky-holder {
    height: 100%;
    position: relative;
    z-index: 2;
    box-shadow: 0 2px 8px rgba(0,0,0,.09);
}

.sticky-header .sticky-holder .sticky-vertical-align-containers {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 33px;
    box-sizing: border-box;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-left {
    position: relative;
    float: left;
    z-index: 2;
    height: 100%;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-left .s-position-left-inner {
    vertical-align: middle;
    display: inline-block;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-left .s-position-left-inner .logo {
    height: auto;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    z-index: 1;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu > ul {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 100%;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu .clearfix::after, 
.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu .clearfix::before {
    content: " ";
    display: table;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu ul li {
    position: relative;
    display: inline-block;
    float: left;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-position: right;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu ul li a {
    font-size: 13px;
    line-height: 16px;
    color: #EEC667;
    letter-spacing: 1px;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu ul li a span.item_inner:hover {
    color: #FFFFFF;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu > ul > li {
    height: 100%;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu > ul > li > a {
    height: 100%;
    position: relative;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    border-style: solid;
    border-width: 0;
    border-color: transparent;
    background-color: transparent;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu > ul > li > a > span.item_outer {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu > ul > li > a span.item_inner {
    letter-spacing: 3px;
    cursor: pointer;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu > ul > li > a span.item_inner::after {
    display: block;
    content: '';
    border-bottom: solid 1px #FFFFFF;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-center .main-menu > ul > li > a span.item_inner:hover::after {
    transform: scaleX(1)
}


.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-right {
    position: relative;
    float: right;
    z-index: 2;
    height: 100%;
    text-align: right;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-right .s-position-right-inner {
    height: 100%;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
}

.sticky-header .sticky-holder .sticky-vertical-align-containers .sticky-position-right .s-position-right-inner .s-right-from-rrss {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding-left: 28px;
    position: relative;
    color: #EEC667;
}

.sticky-vertical-align-containers .sticky-position-left::before, .sticky-vertical-align-containers .sticky-position-center::before, .sticky-vertical-align-containers .sticky-position-right::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-right: -3px;
}



</style>

<script>
    export default {
        data() {
            return {
                windowTop: 0,
                openNavbar: 0,
                openNavbarMs: 0
            };
        },
        methods: {
            smoothScrollNavbar() {
                $('.sm-clickScroll').on('click',function(e){
                    var elemenScroll= $(this).attr('href');
                    $('html,body').animate({
                        scrollTop: $(elemenScroll).offset().top
                    },1200);
                    e.preventDefault();
                });
            },
            onScroll(e) {
                let stikyOn = document.getElementById('sticky_on');
                let stikyOnMob = document.getElementById('sticky_on_mob');

                this.windowTop = e.target.documentElement.scrollTop;

                if(this.windowTop >= 103){
                    stikyOn.classList.add('sticky_navbar')
                    stikyOnMob.classList.add('!translate-y-0')
                    
                    if (this.openNavbar == 1) {
                        this.openNavbarMs = 1
                        this.openNavbar = 0
                    }
                } else {
                    stikyOn.classList.remove('sticky_navbar')
                    stikyOnMob.classList.remove('!translate-y-0')
                    if (this.openNavbarMs == 1) {
                        this.openNavbar = 1
                        this.openNavbarMs = 0
                    }
                }
            },
            openNavbarM(val) {
                if (val === 'open') {
                    this.openNavbar = 1
                } else {
                    this.openNavbar = 0
                }
            },
            openNavbarMS(val) {
                if (val === 'open') {
                    this.openNavbarMs = 1
                } else {
                    this.openNavbarMs = 0
                }
            }
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
            this.smoothScrollNavbar()
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        }
    }
</script>