import axios from "axios";
import { reject } from 'core-js';

export default {
  namespaced: true,
  state: () => ({
  }),
  getters: {
  },
  mutations: {
  },
  actions: {
    async reserve({ commit, state, dispatch }, objeto) {
      let obj = {
            name: objeto.name,
            phone: objeto.phone,
            date: objeto.date,
            time: objeto.time,
            persons: objeto.persons,
            email: objeto.email,
            message: objeto.message
      };
      try {
        let response = await axios.post(
          process.env.VUE_APP_BASE_URL + "/utility/reserve",
          obj
        );
        return response.data;
      } catch(e) {
        return Promise.reject(e.response.data.message)
      }
    }
  }
};
