<template>
    <section id="nosotros_section">
        <div class="nosotros-wrap h-auto min-h-[350px] bg-[#ffffff] px-6 md:px-16 py-24">
            <h4 class="uppercase text-[#282829] tracking-[5px] font_arponasans_light text-center text-sm mb-2">SOBRE NOSOTROS</h4>
            <h2 class="text-3xl text-[#282829] font_theseasons_bd text-center w-full uppercase tracking-[5px]">QUE ES AL BASHA?</h2>
            
            <div class="block lg:flex justify-center mt-8 gap-4">
                <div class="w-full lg:w-1/2 mb-12 lg:mb-0">
                    <div class="w-full h-full flex items-center space-x-3 justify-center">
                        <div class="w-48 h-64 bg-[url('/public/images/sobre_nosotros/sobrenosotros_1.jpg')] bg-cover bg-no-repeat bg-center rounded-2xl mt-10 shadow-md"></div>
                        <div class="w-48 h-64 bg-[url('/public/images/sobre_nosotros/sobrenosotros_2.jpg')] bg-cover bg-no-repeat bg-center rounded-2xl mb-10 shadow-md"></div>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 font_arponasans_light text-gray-600 text-lg">
                    <p class="text-justify">Al Basha mezcla lo antiguo con lo moderno y su nombre significa “El Cacique”
                                            Hemos dedicado más de 13 años ofreciendo los sabores auténticos de la comida 
                                            libanesa e internacional.</p>
                    <p class="text-justify my-4">Nuestra historia comienza con el amor por la cocina, la pasión por los ingredientes 
                                            frescos y la determinación de ofrecer a nuestros comensales una experiencia gastronómica 
                                            memorable.</p>
                    <p class="text-justify my-4">En Al basha, no solo nos encargamos de la comida, sino también de la experiencia en su totalidad. 
                                                 Nuestro restaurante está diseñado para crear un ambiente acogedor y único.</p>
                    <p class="text-justify">Contamos con nuestra área interna que tiene el toque del medio oriente donde te sentirás como en casa, 
                                            al igual como en nuestra amplia terraza que te brindara un ambiente fresco y moderno.</p>
                    </div>
            </div>
        </div>
    </section>
</template>

<style>

</style>

<script>

</script>