<template>
    <section>
        <div class="footer-wrap h-auto min-h-[350px] bg-[#1a1414] pt-10 pb-8 px-6 md:px-10">
            <div class="content_text_footer w-full sm:w-1/3 my-0 mx-auto text-[#c9c9c9]">
                <a href="#container-slider" class="sm-clickScroll"><img class="logo h-auto max-h-20 my-0 mx-auto" src="../../public/images/logo_albasha_png.png" /></a>
                <div class="text-sm">
                    <p class="mt-8">Lunes a Domingo: 12:00pm - 12:00am</p>
                    <p>(+507) 263-2680</p>
                    <p><a href="mailto:albasha.pty@gmail.com" class="hover:text-[#EEC667] duration-300 ease-in-out">albasha.pty@gmail.com</a></p>
                    <p>Calle 49 Este con calle Uruguay, Panama City</p>
                    <span class="inline-block mt-4">
                        <a href="https://www.instagram.com/albashapanama" target="_blank"><font-awesome-icon class="mr-4 text-xl cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-instagram" /></a>
                        <a href="https://www.tiktok.com/@albashapanama" target="_blank"><font-awesome-icon class="mr-4 text-xl cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-tiktok" /></a>
                        <a href="https://wa.me/50768099999" target="_blank"><font-awesome-icon class="text-xl cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-whatsapp" /></a>
                    </span>
                </div>
                <div class="copyRight mt-6">
                    <span class="text-[13px] text-[#8f8f8f]">Copyright © <span id="currentYear"></span> Al Basha Panama <br>Hecho por <a class="cursor-pointer decoration-1 hover:text-[#EEC667] duration-300 font-bold" href="https://www.aimanconsulting.com" target="_blank">Aiman Consulting</a></span>
                </div>
            </div>
        </div>
    </section>
</template>

<style>

</style>

<script>

    export default {
        mounted() {
            let date = new Date()
            let year = date.getFullYear()
            let currentYear = document.getElementById("currentYear");
            currentYear.innerHTML = year;
        }
    }

</script>