import axios from "axios";
import { reject } from 'core-js';

export default {
  namespaced: true,
  state: () => ({
    // one: {},
    all: []
  }),
  getters: {
    all: state => state.all
  },
  mutations: {
    // setOne: (state, data)=>{
    //     state.one = data
    // },
    setAll: (state, data)=>{
        if(data != undefined || data != null){
            state.all = data
        }
    }
  },
  actions: {
    async getAll({ commit, state, dispatch }, type) {

      let filter = 0
      if (type == 'slider') {
        filter = 1
      } else if (type == 'menu') {
        filter = 0
      }

      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + "/image/getAllImages/" + filter
        );
        commit('setAll', response.data.data)
        return response.data.data;
      } catch(e) {
        return Promise.reject(e.response.data.message)
      }
    }
  }
};
