<template>
    <div id="container-slider" class="slider-fullwidth-wrap">
        <div class="arrow-left left-1 md:left-14 h-16 w-16" v-on:click="fntExecuteSlide('prev')">
            <span class="text-4xl md:text-5xl lg:text-6xl"><font-awesome-icon icon="fa-solid fa-chevron-left" /></span>
        </div>
        <div class="arrow-right right-1 md:right-14 h-16 w-16" v-on:click="fntExecuteSlide('next')">
            <span class="text-4xl md:text-5xl lg:text-6xl"><font-awesome-icon icon="fa-solid fa-chevron-right" /></span>
        </div>

        <ul id="slider" class="w-full h-full">
            <li  class="module-slider-wrap relative [clip-path:inset(0)]" v-for="(item, index) in allImages" :style="{ 'opacity': index == 0 ? 1 : 0, 'zIndex': index == 0 ? 1 : 0 }">
                <!-- <div class="img-bg" :style="{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + item.base64 + ')'}"></div> -->
                <img class="object-cover object-center w-full h-full max-w-full fixed animate-[kenburns-top_6.5s_ease-in-out_infinite_alternate-reverse_both]" v-lazy="item.base64" :alt="item.title" />
                <div class="w-full h-full bg-black/40 absolute top-0 right-0 left-0 bottom-0"></div>
                <div class="content-text px-16">
                    <h1 class="font_theseasons_bd text-2xl sm:text-3xl md:text-5xl lg:text-7xl xl:text-8xl tracking-[5px] sm:tracking-[10px] md:tracking-[12px] lg:tracking-[17px] xl:tracking-[21px]">{{ item.title }}</h1>
                    <p class="font_arponasans_light">{{ item.description }}</p>
                <!--<a href="#menu_section" class="sm-clickScroll duration-300 ease-in-out cursor-pointer font_aftikasoft_light tracking-[3px] block sm:inline">IR AL MENU</a>-->
                </div>
            </li>
        </ul>
    </div>
</template>
<style>
.slider-fullwidth-wrap {
    position: relative;
    width: 100%;
    height: 919px;
    display: block;
    overflow: visible;
    max-width: none !important;
}

.slider-fullwidth-wrap .arrow-left {
    position: absolute;
    top: 50%;
    color: #b5b5b5;
    z-index: 2;
    cursor: pointer;
}

.slider-fullwidth-wrap .arrow-left span {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.slider-fullwidth-wrap .arrow-left:hover > span {
    color: #ffffff;
}

.slider-fullwidth-wrap .arrow-right {
    position: absolute;
    top: 50%;
    color: #b5b5b5;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.slider-fullwidth-wrap .arrow-right span {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.slider-fullwidth-wrap .arrow-right:hover > span {
    color: #ffffff;
}

.slider-fullwidth-wrap .module-slider-wrap {
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    /* transition: 1s; */
}

.slider-fullwidth-wrap .module-slider-wrap .img-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-animation: kenburns-top 6.5s ease-in-out infinite alternate-reverse both;
	animation: kenburns-top 6.5s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

#slider li {
    z-index: -1;
    opacity: 0;
}

.slider-fullwidth-wrap .module-slider-wrap .content-text {
    display: block;
    position: absolute;
    top: 350px;
    bottom: 0;
    left: 0;
    right: 0;
}

.slider-fullwidth-wrap .module-slider-wrap .content-text h5 {
    z-index: 5;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    line-height: 23px;
    font-size: 15px;
}

.slider-fullwidth-wrap .module-slider-wrap .content-text h1 {
    z-index: 6;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
}

.slider-fullwidth-wrap .module-slider-wrap .content-text p {
    z-index: 7;
    color: rgb(255, 255, 255);
    white-space: normal;
    text-align: center;
    line-height: 32px;
    letter-spacing: 0px;
    font-size: 18px;
    margin-bottom: 35px;
}

.slider-fullwidth-wrap .module-slider-wrap .content-text a {
    z-index: 8;
    text-transform: uppercase;
    background-color: #fff;
    padding: 16px 36px;
    color: #282827;
    white-space: nowrap;
    line-height: 22px;
    font-size: 12px;
}

.slider-fullwidth-wrap .module-slider-wrap .content-text a:hover {
    background-color: #282827;
    color: #EEC667;
}

</style>
<script>
    import { mapActions, mapGetters } from 'vuex';
    export default {
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters('images', { allImages: 'all' })
        },
        methods: {
            ...mapActions('images', { getAllImages: 'getAll' }),
            getAll() {
                Promise.all([
                    this.getAllImages('slider')
                ]).then(() => {
                })
            },
            fntExecuteSlide(side) {
                let parentTarget = document.getElementById('slider');
                let elements = parentTarget.getElementsByTagName('li');
                let curElement, nextElement;

                for(var i=0; i<elements.length;i++) {
                    if(elements[i].style.opacity==1) {
                        curElement = i;
                        break;
                    }
                }

                if(side == 'prev' || side == 'next') {
                    if(side=="prev") {
                        nextElement = (curElement == 0)?elements.length -1:curElement -1;
                    } else {
                        nextElement = (curElement == elements.length -1)?0:curElement +1;
                    }
                } else {
                    nextElement = side;
                    side = (curElement > nextElement)?'prev':'next';

                    nextElement = (curElement == elements.length -1)?0:curElement +1;
                }

                elements[curElement].style.opacity=0;
                elements[curElement].style.zIndex =0;
                elements[nextElement].style.opacity=1;
                elements[nextElement].style.zIndex =1;
            },
            smoothScrollNavbar() {
                $('.sm-clickScroll').on('click',function(e){
                    var elemenScroll= $(this).attr('href');
                    $('html,body').animate({
                        scrollTop: $(elemenScroll).offset().top
                    },1200);
                    e.preventDefault();
                });
            }
        },
        mounted() {
            this.getAll()
            this.smoothScrollNavbar()
            if(document.querySelector('#container-slider')){
                setInterval(() => {
                    this.fntExecuteSlide('next')
                }, 6500);
            }
        }
    }
</script>