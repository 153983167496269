import { createStore } from "vuex";

import images from "./images";
import images_menu from "./images_menu";
import reservation from "./reservation";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    images,
    images_menu,
    reservation
  }
});
