<template>
  <router-view/>
  <div class="btn-wsp duration-300 ease-in-out">
    <a href="https://wa.me/50768099999" target="_blank" class="flex items-center"><font-awesome-icon class="icono" icon="fa-brands fa-whatsapp" /><span>Contáctenos al Whatsapp</span></a>
  </div>  
  <div id="arrow_top" class="btn-top duration-300 ease-in-out" v-on:click="resetWindowTop()">
    <font-awesome-icon class="icono" icon="fa-solid fa-angle-up" />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background-color: #fff;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  margin: 0px!important;
}

/* font family The Seasons */

@font-face {
  font-family: 'theseasons-bdit';
  src: url('./fonts/the_seasons/theseasons-bdit.otf') format('opentype');
  font-style: normal;
  /* font-weight: normal; */
  text-rendering: optimizeLegibility;
}

.font_theseasons_bdit {
  font-family: 'theseasons-bdit';
}

@font-face {
  font-family: 'theseasons-it';
  src: url('./fonts/the_seasons/theseasons-it.otf') format('opentype');
  font-style: normal;
  /* font-weight: normal; */
  text-rendering: optimizeLegibility;
}

.font_theseasons_it {
  font-family: 'theseasons-it';
}

@font-face {
  font-family: 'theseasons-bd';
  src: url('./fonts/the_seasons/theseasons-bd.otf') format('opentype');
  font-style: normal;
  /* font-weight: normal; */
  text-rendering: optimizeLegibility;
}

.font_theseasons_bd {
  font-family: 'theseasons-bd';
}

@font-face {
  font-family: 'theseasons-reg';
  src: url('./fonts/the_seasons/theseasons-reg.otf') format('opentype');
  font-style: normal;
  /* font-weight: normal; */
  text-rendering: optimizeLegibility;
}

.font_theseasons_reg {
  font-family: 'theseasons-reg';
}

/* fin font family The Seasons */

/* font family Arpona Sans */

@font-face {
  font-family: 'arponasans-regular';
  src: url('./fonts/arpona_sans/arponasans-regular.otf') format('opentype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

.font_arponasans_reg {
  font-family: 'arponasans-regular';
}

@font-face {
  font-family: 'arponasans-medium';
  src: url('./fonts/arpona_sans/arponasans-medium.otf') format('opentype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

.font_arponasans_med {
  font-family: 'arponasans-medium';
}

@font-face {
  font-family: 'arponasans-light';
  src: url('./fonts/arpona_sans/arponasans-light.otf') format('opentype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

.font_arponasans_light {
  font-family: 'arponasans-light';
}

/* fin font family Arpona Sans */

/* font Great vibes */

@font-face {
  font-family: 'GreatVibesRegular';
  src: url('./fonts/GreatVibesRegular.ttf') format('truetype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

.font_greatvibes_reg {
  font-family: 'GreatVibesRegular';
}

/* fin font Great vibes */

/* font Great vibes */

@font-face {
  font-family: 'OranienbaumRegular';
  src: url('./fonts/OranienbaumRegular.ttf') format('truetype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

.font_oranienbaum_reg {
  font-family: 'OranienbaumRegular';
}

/* fin font Great vibes */

@font-face {
  font-family: 'GoodVibrationsScript';
  src: url('./fonts/GoodVibrationsScript.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.font_goodvibrations {
  font-family: 'GoodVibrationsScript';
}

@font-face {
  font-family: 'aftikasoft-regular';
  src: url('./fonts/aftikasoft/aftikasoft-regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.font_aftikasoft_regular {
  font-family: 'aftikasoft-regular';
}

@font-face {
  font-family: 'aftikasoft-light';
  src: url('./fonts/aftikasoft/aftikasoft-light.otf') format('opentype');
  font-style: normal;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}

.font_aftikasoft_light {
  font-family: 'aftikasoft-light';
}

@font-face {
  font-family: 'aftikasoft-semibold';
  src: url('./fonts/aftikasoft/aftikasoft-semibold.otf') format('opentype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

.font_aftikasoft_semibold {
  font-family: 'aftikasoft-semibold';
}

.btn-wsp {
  position: fixed;
  right: 0px;
  z-index: 6669992;
  font-size: 10px !important;
  letter-spacing: .11em;
  display: inline-flex;
  height: 45px;
  width: 200px;
  cursor: pointer;
}

.btn-top {
  position: fixed;
  right: 0px;
  z-index: 6669992;
  font-size: 10px !important;
  letter-spacing: .11em;
  display: block;
  height: 45px;
  width: 45px;
  cursor: pointer;
}

.btn-wsp {
  top: calc(85% - 25px);
  background-color: #EEC667;
  color: #282827;
  -webkit-transform: translateX(75%);
  transform: translateX(77%);
  border-radius: 5px 0 0 5px;
}

.btn-wsp:hover {
  background-color: #282827;
  color: #EEC667;
  transform: translateX(0);
}

.btn-top {
  top: calc(85% + 25px);
  background-color: #282829;
  box-shadow: 0 0 9.8px 0.2px rgba(0, 0, 0, 0.1);
  color: #EEC667;
  -webkit-transform: translateX(110%);
  transform: translateX(110%);
  border-radius: 5px 0 0 5px;
}

.btn-top.arrow_up {
  transform: translateX(0)!important;
}

.btn-top:hover {
  background-color: #EEC667;
  color: #282829;
}

.btn-wsp .icono {
  font-size: 30px;
  padding: 7px 7px 7px 13px;
}

.btn-wsp span {
  padding: 15px 0 15px 5px;
}

.btn-top .icono {
  font-size: 30px;
  padding: 7px;
}
</style>

<script>
  export default {
    components: {
    },    
    data() {
      return {
        windowTop: 0
      };
    },
    methods: {
      resetWindowTop() {
        window.scrollTo({
          top:0, behavior:"smooth"
        })
      },
      onScroll(e) {
        let arrowTop = document.getElementById('arrow_top');

        this.windowTop = e.target.documentElement.scrollTop;
        
        if(this.windowTop >= 103){
          arrowTop.classList.add('arrow_up')
        } else {
          arrowTop.classList.remove('arrow_up')
        }
      }
    },
    mounted() {
      window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.onScroll);
    }
  }
</script>
