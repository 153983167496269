import { createApp } from 'vue'
import './assets/css/tw-style.css'
import App from './App.vue'
import router from './router'
import store from './store'

import VueClientRecaptcha from 'vue-client-recaptcha'

import VueLazyLoad from 'vue3-lazyload'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import {faAngleUp, faChevronLeft, faChevronRight, faMobileScreenButton, faEnvelope, faLocationDot, faClock, faBars, faXmark, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faTiktok, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(faInstagram, faTiktok, faWhatsapp, faAngleUp, faChevronLeft, faChevronRight, faMobileScreenButton, faEnvelope, faLocationDot, faClock, faBars, faXmark, faCircleXmark, faCircleCheck)

const app = createApp(App);

app.use(VueSweetalert2)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component("VueClientRecaptcha", VueClientRecaptcha)

app.use(VueLazyLoad, {
})

app.use(store)
app.use(router)
app.mount('#app')
