<template>
    <section id="reservation_section">
        <div class="reservation-wrap h-auto min-h-[350px] bg-[#ffffff] py-20 px-6 md:px-10">
            <h4 class="uppercase text-[#ffffff] tracking-[5px] font_arponasans_light text-center text-sm mb-2">Buen ambiente y comida</h4>
            <h2 class="text-xl sm:text-3xl text-[#ffffff] font_theseasons_bd text-center w-full uppercase tracking-[5px]">Reserve una mesa con nosotros</h2>
            
            <div class="mt-8 w-full">
                <div class="w-full lg:w-1/2 my-0 mx-auto">
                    <form id="formReserve" :model="form" ref="form">
                        <div class="grid grid-cols-2 gap-4">
                            <div class="col-span-2 sm:col-span-1">
                                <input type="text" placeholder="Nombre" v-model="form.name" required class="appearance-none block w-full bg-[#e9e9e9] py-5 px-6 focus:outline-none text-gray-800 border focus:bg-[#ffffff] focus:border-[#EEC667]">
                            </div>
                            <div class="col-span-2 sm:col-span-1">
                                <input type="text" placeholder="Teléfono" maxlength="8" v-on:keyup="formatPhoneNumber(form.phone)" v-model="form.phone" required class="appearance-none block w-full bg-[#e9e9e9] py-5 px-6 focus:outline-none text-gray-800 border focus:bg-[#ffffff] focus:border-[#EEC667]">
                            </div>                        
                            <div class="col-span-2 sm:col-span-1">
                                <input type="date" id="dateReserve" placeholder="Fecha" v-model="form.date" required class="appearance-none block w-full bg-[#e9e9e9] py-5 px-6 focus:outline-none text-gray-800 border focus:bg-[#ffffff] focus:border-[#EEC667]">
                            </div>                        
                            <div class="col-span-2 sm:col-span-1">
                                <select v-model="form.time" class="block w-full h-[68px] bg-[#e9e9e9] py-5 px-6 focus:outline-none text-gray-800 border focus:bg-[#ffffff] focus:border-[#EEC667]">
                                    <option value="" disabled selected>Selecciona una hora</option>
                                    <option v-for="hour in hours" :value="hour">{{ hour }}</option>
                                </select>
                            </div>                        
                            <div class="col-span-2 sm:col-span-1">
                                <input type="number" placeholder="Personas" v-model="form.persons" required class="appearance-none block w-full bg-[#e9e9e9] py-5 px-6 focus:outline-none text-gray-800 border focus:bg-[#ffffff] focus:border-[#EEC667]">
                            </div>                        
                            <div class="col-span-2 sm:col-span-1">
                                <input type="email" placeholder="Correo" v-model="form.email" @change="validateEmail(form.email)" required class="appearance-none block w-full bg-[#e9e9e9] py-5 px-6 focus:outline-none text-gray-800 border focus:bg-[#ffffff] focus:border-[#EEC667]" :class="validEmail == 2 ? 'bg-red-300 focus:ring-red-300' : ''">
                            </div>                        
                            <div class="col-span-2">
                                <textarea rows="4" placeholder="Mensaje" v-model="form.message" required class="appearance-none resize-none block w-full bg-[#e9e9e9] py-5 px-6 focus:outline-none text-gray-800 border focus:bg-[#ffffff] focus:border-[#EEC667]"></textarea>
                            </div>
                        </div>
                        <div class="md:flex items-center justify-center md:space-x-4 mt-8">
                            <div class="flex justify-center h-[60px] mb-4 md:mb-0">
                                <div class="relative">
                                    <input type="text" class="h-full appearance-none block w-full bg-[#f3f3f3] py-5 px-6 focus:outline-none text-gray-800 border focus:bg-[#ffffff] focus:border-[#EEC667]" :class="vCaptcha == 1 ? 'bg-green-300 focus:bg-green-300' : (vCaptcha == 2 ? 'bg-red-300 focus:bg-red-300' : '')" v-model="codeValue" />
                                    <span class="absolute text-xl right-4 top-4">
                                        <font-awesome-icon v-if="vCaptcha == 1" icon="fa-solid fa-circle-check" class="text-green-800" />
                                        <font-awesome-icon v-else-if="vCaptcha == 2" icon="fa-solid fa-circle-xmark" class="text-red-800" />
                                    </span>
                                </div>
                                <VueClientRecaptcha :value="codeValue" @getCode="captchaCode" @isValid="isCaptchaValid" class="justify-normal" />
                            </div>
                            <button v-on:click.prevent="reservation()" class="flex-auto md:flex-none font_arponasans_light w-full md:w-auto h-[60px] py-0 px-7 uppercase bg-transparent border-2 border-white text-white font-sm hover:bg-white hover:text-[#282829] duration-300 tracking-[2px]">Reservar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
@import url("/node_modules/vue-client-recaptcha/dist/style.css");

.reservation-wrap {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url('/public/images/reservation_background.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}
#dateReserve::-webkit-calendar-picker-indicator {
  padding-left: 35%;
}

@media screen and (max-width: 390px) {
    #dateReserve::-webkit-calendar-picker-indicator {
        padding-left: 45%;
    }
}
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
        },
        data() {
            return {
                form: {
                    name: '',
                    phone: '',
                    date: '',
                    time: '',
                    persons: '',
                    email: '',
                    message: ''
                },
                vCaptcha: 0,
                codeCap: '',
                codeValue: '',
                validEmail: 0
            }
        },
        computed: {
            hours() {
                const hours = [];
                for (let hour = 12; hour <= 23; hour++) {
                    hours.push(`${hour % 12 === 0 ? 12 : hour % 12}:00 ${hour >= 12 ? 'pm' : 'am'}`);
                    hours.push(`${hour % 12 === 0 ? 12 : hour % 12}:30 ${hour >= 12 ? 'pm' : 'am'}`);
                }
                return hours;
            }
        },
        methods: {
            ...mapActions('reservation', { reserve: 'reserve' }),
            captchaCode(code) {
                this.codeCap = code
            },
            isCaptchaValid(val) {
                if (val) {
                    this.vCaptcha = 1
                } else if (!val && this.codeValue.length >= 5) {
                    this.vCaptcha = 2
                } else {
                    this.vCaptcha = 0
                }
            },
            reservation() {
                if (this.vCaptcha == 1) {
                    if (this.form.name && this.form.phone && this.form.date && this.form.time && this.form.persons && this.form.email && this.validEmail == 1) {
                        this.reserve(this.form)
                        .then(resp => {
                            this.$swal({
                                title: "Reserva Exitosa",
                                html: "Se ha hecho la reserva, en breve se enviara un resumen a su correo como respaldo, gracias por su preferencia.",
                                icon: "success",
                                showCancelButton: false,
                                confirmButtonColor: "#dfb03f",
                                confirmButtonText: "Aceptar",
                                allowEscapeKey: false,
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    document.getElementById("formReserve").reset();
                                }
                            })
                        }).catch((error) => {
                            this.$swal({
                                title: "Error",
                                html: "Ha ocurrido un error al enviar los datos, favor de volver a completar el formulario.",
                                icon: "error",
                                showCancelButton: false,
                                confirmButtonColor: "#dfb03f",
                                confirmButtonText: "Aceptar",
                                allowEscapeKey: false,
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    document.getElementById("formReserve").reset();
                                }
                            })
                        })
                    } else {
                        this.$swal({
                            title: "Campos requeridos",
                            html: "Debe completar todos los campos",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonColor: "#dfb03f",
                            confirmButtonText: "Aceptar",
                            allowEscapeKey: false,
                            allowOutsideClick: false
                        })
                    }
                } else {
                    this.vCaptcha = 2
                }
            },
            validateEmail(email) {
                var validate =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

                if (email && validate.test(email)) {
                    this.validEmail = 1
                } else if (email && !validate.test(email)) {
                    this.validEmail = 2
                } else {
                    this.validEmail = 0
                }
            },
            formatPhoneNumber(phone) {

                const phoneNumber = phone.replace(/\D/g, '')

                if (phoneNumber.length < 8) {
                    phone = phoneNumber
                    return
                }

                let formattedPhoneNumber = ''

                if (phoneNumber.length === 8) {
                    formattedPhoneNumber = '(+507) ' + phoneNumber.slice(-8, -4) + '-' + phoneNumber.slice(-4)
                }

                phone = formattedPhoneNumber

                this.form.phone = phone
            }
        },
        mounted() {
            let fecha = new Date()
            let fechaMin = fecha.toISOString().split('T')[0]
            document.querySelector('#dateReserve').min = fechaMin;
        }
    }

</script>