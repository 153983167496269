<template>
    <section>
        <div class="video-wrap h-auto min-h-[350px] bg-[#f4f4f4] px-6 md:px-16 py-24">
            <h4 class="uppercase text-[#282829] tracking-[5px] font_arponasans_light text-center text-base mb-2">Mas de nosotros</h4>

            <div class="w-full mt-8 grid grid-cols-1 lg:grid-cols-1 gap-4">
                <div class="content-video mt-8">
                    <h2 class="text-xl md:text-2xl text-[#282829] font_theseasons_bd text-center w-full uppercase tracking-[5px]">Vive la experiencia Al Basha</h2>
                    <video src="../../public/videos/Al_Basha_2_1.mp4" controls controlslist="nodownload" width="auto" class="max-h-96 my-4 mx-auto w-full md:w-[65%] bg-black"></video>
                </div>
                <div class="content-video mt-8">
                    <h2 class="text-xl md:text-2xl text-[#282829] font_theseasons_bd text-center w-full uppercase tracking-[5px]">Disfruta del menu Ejecutivo</h2>
                    <video src="../../public/videos/Al_Basha_1.mp4" controls controlslist="nodownload" width="auto" class="max-h-96 my-4 mx-auto w-full md:w-[65%] bg-black"></video>
                </div>
            </div>
            
        </div>
    </section>
</template>

<style>

</style>

<script>

</script>