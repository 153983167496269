<template>
  <navbar></navbar>
  <slider></slider>
  <slogan></slogan>
  <menu_section></menu_section>
  <nosotros_section></nosotros_section>
  <video_section></video_section>
  <contactenos></contactenos>
  <reservation></reservation>
  <footer_section></footer_section>
</template>

<script>
  import navbar from '@/components/navbar.vue'
  import slider from '@/components/slider.vue'
  import slogan from '@/components/slogan.vue'
  import menu_section from '@/components/menu.vue'
  import nosotros_section from '@/components/sobrenosotros.vue'
  import video_section from '@/components/video.vue'
  import contactenos from '@/components/contactenos.vue'
  import reservation from '@/components/reservarmesa.vue'
  import footer_section from '@/components/footer.vue'

  export default {
    components: {
      navbar,
      slider,
      slogan,
      menu_section,
      nosotros_section,
      video_section,
      contactenos,
      reservation,
      footer_section
    }
  }
</script>
