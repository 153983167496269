<template>
    <section id="contacto_section">
        <div class="contacto-wrap h-auto min-h-[350px] bg-[#ffffff] px-6 md:px-16 py-24">
            <div class="lg:flex justify-center mt-8 gap-6 w-3/4 my-0 mx-auto">
                <div class="w-full lg:w-1/2">
                    <h2 class="block lg:hidden text-xl sm:text-2xl text-[#282829] font_theseasons_bd w-full uppercase tracking-[5px] mb-6">¿NECESITA AYUDA? CONTACTENOS</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d246.3082981541622!2d-79.525119!3d8.978334!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8faca8e66b906c0f%3A0x4b223185c8d1377c!2sAl%20Basha!5e0!3m2!1sen!2slb!4v1709925939807!5m2!1sen!2slb" 
                    width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="w-full lg:w-1/2 text-gray-600 text-left">
                    <h2 class="hidden lg:block text-xl sm:text-2xl text-[#282829] font_theseasons_bd w-full uppercase tracking-[5px]">¿NECESITA AYUDA? CONTACTENOS</h2>
                    <p class="my-6 font_arponasans_light">Si necesita comunicarse con nosotros, a continuación le dejamos nuestros canales de contacto:</p>
                    <div class="mb-2 grid justify-items-center sm:flex sm:justify-items-start">
                        <span class="w-9 h-9 bg-[#1a1414] text-[#EEC667] rounded-full flex items-center">
                            <font-awesome-icon class="my-0 mx-auto" icon="fa-solid fa-mobile-screen-button" />
                        </span> 
                        <p class="mt-2 ml-4">(+507) 263 2680</p>
                    </div>
                    <div class="mb-2 grid justify-items-center sm:flex sm:justify-items-start">
                        <span class="w-9 h-9 bg-[#1a1414] text-[#EEC667] rounded-full flex items-center">
                            <font-awesome-icon class="my-0 mx-auto" icon="fa-solid fa-envelope" />
                        </span> 
                        <a href="mailto:albasha.pty@gmail.com" class="hover:text-[#EEC667] duration-300 ease-in-out mt-2 ml-4">albasha.pty@gmail.com</a>
                    </div>                    
                    <div class="mb-2 grid justify-items-center sm:flex sm:justify-items-start">
                        <span class="w-9 h-9 bg-[#1a1414] text-[#EEC667] rounded-full flex items-center">
                            <font-awesome-icon class="my-0 mx-auto" icon="fa-brands fa-whatsapp" />
                        </span>
                        <a href="https://wa.me/50768099999" target="_blank" class="hover:text-[#EEC667] duration-300 ease-in-out mt-2 ml-4 underline">Whatsapp</a>
                    </div>
                    <div class="mb-2 grid justify-items-center sm:flex sm:justify-items-start">
                        <span class="w-9 h-9 bg-[#1a1414] text-[#EEC667] rounded-full flex items-center">
                            <font-awesome-icon class="my-0 mx-auto" icon="fa-brands fa-instagram" />
                        </span>
                        <a href="https://www.instagram.com/albashapanama" target="_blank" class="hover:text-[#EEC667] duration-300 ease-in-out mt-2 ml-4 underline">Instagram</a>
                    </div>
                    <div class="mb-2 grid justify-items-center sm:flex sm:justify-items-start">
                        <span class="w-9 h-9 bg-[#1a1414] text-[#EEC667] rounded-full flex items-center">
                            <font-awesome-icon class="my-0 mx-auto" icon="fa-brands fa-tiktok" />
                        </span>
                        <a href="https://www.tiktok.com/@albashapanama" target="_blank" class="hover:text-[#EEC667] duration-300 ease-in-out mt-2 ml-4 underline">TikTok</a>
                    </div>
                    <div class="mb-2 grid justify-items-center sm:flex sm:justify-items-start">
                        <span class="w-9 h-9 bg-[#1a1414] text-[#EEC667] rounded-full flex items-center">
                            <font-awesome-icon class="my-0 mx-auto" icon="fa-solid fa-location-dot" />
                        </span> 
                        <p class="mt-2 ml-4">Calle 49 Este con calle Uruguay, Panama City</p>
                    </div>
                    <div class="mb-2 grid justify-items-center sm:flex sm:justify-items-start">
                        <span class="w-9 h-9 bg-[#1a1414] text-[#EEC667] rounded-full flex items-center">
                            <font-awesome-icon class="my-0 mx-auto" icon="fa-solid fa-clock" />
                        </span> 
                        <p class="mt-2 ml-4">Lunes a Domingo de 12:00pm - 12:00am</p>
                    </div>
                    <!-- <div class="flex">
                        <span class="w-9 h-9 bg-[#eec667] rounded-full flex items-center">
                            <font-awesome-icon class="my-0 mx-auto" icon="fa-solid fa-icons" />
                        </span>
                        <span class="inline-block mt-2 ml-4">
                            <a href="https://www.instagram.com/albashapanama" target="_blank"><font-awesome-icon class="mr-2 text-xl cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-instagram" /></a>
                            <a href="https://www.tiktok.com/@albashapanama" target="_blank"><font-awesome-icon class="mr-2 text-xl cursor-pointer hover:text-[#EEC667] duration-300 ease-in-out" icon="fa-brands fa-tiktok" /></a>
                        </span>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>

<style>

</style>

<script>

</script>