<template>
    <section>
        <div class="slogan-wrap h-auto min-h-[400px] bg-[#1a1414] flex items-center shadow-inner">
            <div class="flex items-center w-11/12 my-0 mx-auto">
                <div class="w-full p-4 text-center">
                    <span class="text-[30px] sm:text-[47px] md:text-[60px] lg:text-[86px] text-[#EEC667] block font_oranienbaum_reg">"El auténtico sabor"</span>
                    <span class="text-lg sm:text-3xl md:text-4xl lg:text-5xl text-[#ffffff] block -mt-1 sm:-mt-4 font_theseasons_bd">de la comida libanesa...</span>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
/* .slogan-wrap h2 {
    background: -webkit-linear-gradient(#EEC667, #ffffff, #EEC667);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */
</style>

<script>
</script>