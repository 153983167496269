<template>
    <section id="menu_section">
        <div class="h-[300px] relative flex justify-center items-center [clip-path:inset(0)]">
            <img src="/img/background_section_menu.jpg" class="object-cover object-center w-full h-full top-0 fixed" />
            <div class="w-full h-full bg-black/55 absolute top-0 right-0 left-0 bottom-0"></div>
            <div class="title-section absolute inset-x-0">
                <h2 class="text-3xl sm:text-5xl font_theseasons_bd tracking-wide text-white">De Nuestro Menu</h2>
            </div>
        </div>
        <div class="bg-zinc-100 w-full bg_menu_sect">
            <div class="wrap-cards box-border w-full px-3.5 font_arponasans_med tracking-[1px]">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                    <div v-if="allImagesM.length <= 0">
                        <article class="mb-4">
                            <img v-lazy="{ src: '/images/img_menu/default.png' }" class="object-cover object-center w-full h-80 max-w-full rounded-lg"  alt="No hay Imagen" />
                            <div class="mt-3">
                                <h3 clasS="text-[#eec667] text-xl text-left">No existen imagenes</h3>
                            </div>
                        </article>
                    </div>
                    <div v-for="item in allImagesM" v-else>
                        <article class="mb-4">
                            <img class="object-cover object-center w-full h-80 max-w-full rounded-lg hover:scale-105 hover:-rotate-2 duration-300 cursor-pointer" v-lazy="item.base64" :alt="item.title" v-on:click="openLightbox(item)" />
                            <div class="mt-3">
                                <h3 clasS="text-[#eec667] text-xl text-left">{{ item.title }}</h3>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="w-full mt-14 mb-12">
                    <a href="https://al-basha.cluvi.co/al-basha/menu-digital/home" target="_blank" class="px-20 py-5 text-[#282829] bg-[#FFFFFF] tracking-[3px] text-lg cursor-pointer shadow-md btn-menu-more block md:inline">
                        <span class="hidden sm:inline">Menu Completo</span>
                        <span class="inline sm:hidden">Menu Completo</span>
                    </a>
                </div>
            </div>            
        </div>
        <!-- lightbox -->
        <div id="lightbox" class="fixed w-full h-wull z-[150] bg-black/90 top-0 bottom-0 left-0 right-0 hidden items-center">
            <div class="bg-slate-200 w-[90%] lg:w-10/12 min-h-80 h-auto mx-auto rounded-md relative">
                <div class="flex items-center justify-center p-[5px] max-h-[700px] overflow-auto sm:my-[5px]">
                    <img class="mt-auto" v-lazy="objMenu.image" :alt="objMenu.title" />
                </div>
                <span class="absolute -top-[2.50rem] right-0 md:-top-[1.75rem] md:-right-[1.80rem] text-3xl hover:text-slate-400 text-slate-200 cursor-pointer shadow-sm duration-200 z-[155]" v-on:click="closeLightbox()">
                    <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                </span>
                <div class="h-28 w-full text-left bg-black/70 p-3 absolute bottom-0 text-slate-200">
                    <h4 class="text-2xl font_arponasans_med">{{ objMenu.title }}</h4>
                    <p class="text-lg font_arponasans_light">{{ objMenu.description }}</p>
                </div>
            </div>
        </div>
        <!-- fin lightbox -->
    </section>
</template>
<style>
.background-menu {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/images/img_menu/background_section_menu.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

.wrap-cards {
    padding: 5.2% 1.2%;
    width: 90%;
    margin: 0 auto;
}

.wrap-cards .btn-menu-more {
    background: linear-gradient(to left, #ffffff 50%, #282829 50%);
    background-size: 201% 100%;
    background-position:right bottom;
    transition:all 0.5s ease;
}

.wrap-cards .btn-menu-more:hover {
    background-position:left bottom;
    color: #ffffff;
}

.bg_menu_sect {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/images/albasha_fondo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
</style>
<script>
    import { mapActions, mapGetters } from 'vuex';
    export default {
        data() {
            return {
                objMenu: {
                    title: '',
                    description: '',
                    image: ''
                }
            }
        },
        computed: {
            ...mapGetters('images_menu', { allImagesM: 'all' })
        },
        methods: {
            ...mapActions('images_menu', { getAllImagesM: 'getAll' }),
            getAll() {
                Promise.all([
                    this.getAllImagesM('menu')
                ]).then(() => {
                })
            },
            openLightbox(item) {
                let lightbox = document.getElementById('lightbox')
                    
                if (item) {
                    this.objMenu.title = item.title
                    this.objMenu.description = item.description
                    this.objMenu.image = item.base64
                    lightbox.classList.add('flex')
                    lightbox.classList.remove('hidden')
                }
            },
            closeLightbox() {
                let lightbox = document.getElementById('lightbox')

                this.objMenu.title = ''
                this.objMenu.description = ''
                this.objMenu.image = ''
                lightbox.classList.remove('flex')
                lightbox.classList.add('hidden')
            }
        },
        mounted() {
            this.getAll()
        }
    }
</script>